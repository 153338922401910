import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import {
  Home,
  Expertise,
  Product,
  ExpertiseData,
  Works,
  Bp,
  Bpl,
  CeoTracker,
  CopReport,
  Deutsche,
  Dtcp,
  FarmerVoice,
  Malteser,
  // Mars,
  NetClean,
  Newwaters,
  Uniper,
  Volkswagen,
} from './pages';
import './App.css';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/home" element={<Home />} />
        <Route path="/creativeCommunication" element={<Expertise />} />
        <Route path="/digitalExperience" element={<Product />} />
        <Route path="/dataVisualization" element={<ExpertiseData />} />
        <Route path="/ourWork" element={<Works />} />
        <Route path="/ourWork/bp" element={<Bp />} />
        <Route path="/ourWork/bpi" element={<Bpl />} />
        <Route path="/ourWork/ceoTracker" element={<CeoTracker />} />
        <Route path="/ourWork/copReport" element={<CopReport />} />
        <Route path="/ourWork/deutscheBahn" element={<Deutsche />} />
        <Route path="/ourWork/dtcp" element={<Dtcp />} />
        <Route path="/ourWork/farmerVoice" element={<FarmerVoice />} />
        <Route path="/ourWork/malteser" element={<Malteser />} />
        {/* <Route path="/ourWork/mars" element={<Mars />} /> */}
        <Route path="/ourWork/netClean" element={<NetClean />} />
        <Route path="/ourWork/newWaters" element={<Newwaters />} />
        <Route path="/ourWork/uniper" element={<Uniper />} />
        <Route path="/ourWork/volkswagen" element={<Volkswagen />} />
      </Routes>

    </BrowserRouter>
  );
}

export default App;
